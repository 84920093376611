<template>
    <div class="about">
        <div class="about-wrapper container">
            <h2>{{ $t('about_us') }}</h2>
            <p class="title">{{ $t('about_us_beginning') }}</p>
            <p class="desc">{{ $t('about_us_desc_first') }}</p>
            <p class="desc last">{{ $t('about_us_desc_second') }}</p>

            <p class="ender">{{ $t('find_life_interest') }}</p>
            
            <div class="founders-container">
                <h2>{{ $t('founders') }}</h2>
                <div class="image-container">
                    <div class="profile-container">
                        <img @click="openModal(0)" :src="require('@/assets/img/founder1_thumb.jpg')" alt="Photo">
                        <p class="name">{{ $t('siang_ying_lee') }}</p>
                        <p class="position">{{ $t('co_founder') }}</p>
                    </div>

                    <div class="profile-container">
                        <img @click="openModal(1)" :src="require('@/assets/img/founder2_thumb.png')" alt="Photo">
                        <p class="name">{{ $t('lin_shang_neng') }}</p>
                        <p class="position">{{ $t('co_founder') }}</p>
                    </div>

                    <div class="profile-container">
                        <img @click="openModal(2)" :src="require('@/assets/img/founder3_thumb.png')" alt="Photo">
                        <p class="name">{{ $t('charles_lo') }}</p>
                        <p class="position">{{ $t('Classmade Founder') }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-container" v-if="showModal">
            <Founder :founder="founder" class="founder-container" @close="close"/>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            showModal: false,
            founder: null,
            founders: [
                {
                id: "1",
                img: require("@/assets/img/founder1.jpg"),
                thumb: require("@/assets/img/founder1_thumb.jpg"),
                name: "siang_ying_lee",
                role: "co_founder",
                education_en: `Master, Psychology (section Counseling and clinical ), National Chengchi University`,
                education_cn:`國立政治大學心理學系臨床與諮商組碩士`,
                current_position_en: `Counseling psychology(since 2007)
Dream light workshop founder
Supervisor of psychological counseling practice`,
                current_position_cn: `諮商心理師（2007年起）
夜夢晨光工作室創辦人
諮商督導`,
                certificate_1_en: `Registered Counseling Psychologist, 2007 National Professional and Technical Higher Examination, Ministry of Examination, Taiwan`,
                certificate_1_cn: `國家高考諮商心理師`,
                publication_1_en: `● Lin, S. N., Lee, S. Y. (2020). More Than Your Life Interests. Night Dreams and Day Light Studio, Hsinchu, Taiwan.`,
                publication_1_cn:`李香盈、林上能（2020）。興趣和你想的不一樣：尋嘗日的運用。新竹：夜夢晨光工作室。`,
                publication_2_en: `● Lee, S. Y, Lin, S. N. (2018). Find Life Interests Exploration Cards. Night Dreams and Day Light Studio, Hsinchu, Taiwan.
● Lee, S. Y. (2018). Dream, The Therapeutic Guide In Deep Sleep. PsyGarden, Taipei, Taiwan.
● Lee, S. Y. (2020). Introspective Lights: Dream Guidance Cards. Night Dreams and Day Light Studio, Hsinchu, Taiwan.
● Lee, S. Y. (2018). Symbolic Reflections: Dream Exploration Cards. Night Dreams and Day Light Studio, Hsinchu, Taiwan.
● Lee, S. Y. (2013). Reveal The Secrets of Dreams. Night Dreams and Day Light Studio, Hsinchu, Taiwan.`,
                publication_2_cn:`李香盈、林上能（2018）。尋．嘗日：生活興趣探索卡。新竹：夜夢晨光工作室。
李香盈（2018）。夢，沉睡的療癒力。臺北：心靈工坊。
李香盈（2020）。光映：夢境指引卡。新竹：夜夢晨光工作室。
李香盈（2018）。映象：夢境探索卡。新竹：夜夢晨光工作室。
李香盈（2013）。揭開夢境的秘密。新竹：夜夢晨光工作室。`,
                work_en: `● Contract lecture, occupational consultation company
● Lecture/occupational consultant/ supervisor, several counties’ employment centers
● Work as an counseling psychologist/lecturer in schools, social welfare institutions, enterprises, and counseling institutions (with thousands of lecture events )
● Counseling psychologist, community mental health center
● Director general, Taoyuan County Counseling Psychology Association
● Adjunct lecturer, Department of Educational Psychology and Counseling, National Tsing Hua University
● Lecturer, Office of Continuing Education, Chung Yuan Christian University
● Full-time & adjunct counseling psychologist, Chung Yuan Christian University`,
                work_cn: `職涯顧問公司 約聘講師 
各縣市就業中心講師、職涯諮詢師、督導
各機構學校、社福機構、企業、諮商所合作心理師、講師(演講場次超過千場)
社區心理衛生中心值班心理師
桃園縣諮商心理師公會總幹事
國立清華大學教育心理與諮商學系兼任講師
中原大學推廣教育部課程講師
中原大學專任、兼任心理師 `
                },
                {
                id: "2",
                img: require("@/assets/img/founder2.png"),
                thumb: require("@/assets/img/founder2_thumb.png"),
                name: "lin_shang_neng",
                role: "co_founder",
                education_en: `Master, Psychology and Counseling, National Taipei University of Education
Doctoral program, Education (Educational Psychology and Guidance), National Chengchi University`,
                education_cn: `國立臺北教育大學心理與諮商學系碩士
國立政治大學教育學系博士班教育心理與輔導學組進修中`,
                current_position_en: `Counseling psychologist, Light Heart Counseling Center, Taipei, Taiwan
Secretary General, Taiwan Counseling Psychologist Union, Taiwan
Vice President, Taiwan Society of Adlerian Psychology (affiliate of NASAP)
Executive Director, Taipei Counseling Psychologist Association, Taiwan
Director, Taiwan Guidance and Counseling Association, Taiwan`,
                current_position_cn: `點亮心燈諮商中心心理諮商所諮商心理師
諮商心理師公會全國聯合會常務理事
臺灣阿德勒心理學會副理事長
臺北市諮商心理公會常務理事
臺灣心理專業發展協會監事
臺灣輔導與諮商學會理事`,
                certificate_1_en: `● Registered Counseling Psychologist, 2010 National Professional and Technical Higher Examination, Ministry of Examination, Taiwan
● Licensed Guidance and Counseling, Career Planning Teacher of Middle High Education, Ministry of Education, Taiwan
● Licensed Parent and Teacher Consultant/ Lecturer/ Supervisor, Career Coach, Marriage and Couple Consultant, North America Society of Adlerian Psychology (U.S. ) and Taiwan Society of Adlerian Psychology (Taiwan)`,
                certificate_1_cn: `國家高考諮商心理師
教育部中等學校輔導科、生涯規劃科教師證
北美及臺灣阿德勒心理學會雙認證親師諮詢師、講師及督導;生涯教練、婚姻伴侶諮詢師`,
                certificate_2_en: `● Registered Career Development Advisor and Instructor, National Career Development Association, U.S.
● Registered Supervisor of School Guidance and Counseling Psychology, Taiwan Guidance and Counseling Association`,
                certificate_2_cn: `美國生涯發展學會 (NCDA) 認證生涯發展諮詢師 (CDA) 及教練 (CDI)
全球生涯與教育品質評估（GCQA）認證國際職業策略規劃師培訓師（SCPS）
臺灣輔導與諮商學會認證諮商心理、學校輔導督導`,
                publication_2_en: `● Lin, S. N., Lee, S. Y. (2020). More Than Your Life Interests. Night Dreams and Day Light Studio, Hsinchu, Taiwan.
● Lin, S. N., Lee, S. Y. (2018). Find Life Interests Exploration Cards. Night Dreams and Day Light Studio, Hsinchu, Taiwan.`,
                publication_2_cn: `林上能、李香盈（2020）。興趣和你想的不一樣：尋嘗日的運用。新竹：夜夢晨光工作室。
林上能、李香盈（2018）。尋．嘗日：生活興趣探索卡。新竹：夜夢晨光工作室。`,
                work_en: `● Counseling Psychologist（since2010）
● Adjunct lecturer, Department of Educational Psychology and Counseling, National Tsing Hua University, Taiwan
● Guidance teacher and guidance section director, Taipei Municipal Chenggong High School, Taiwan (8years)
● Consultant/ lecturer/supervisor, occupational consultation companies, employment service centers, enterprises, and college career development centers
● Lecturer/supervisor in schools, social welfare institutions, and many organizations`,
                work_cn: `諮商心理師（2010年起）
國立清華大學教育心理與諮商學系兼任講師
臺北市立成功高中輔導教師、輔導組長8年
企業、職涯顧問公司與就業服務中心講師、督導
各機關單位、學校、社福機構講師、督導`
                },

                {
                id: "3",
                img: require("@/assets/img/founder3.png"),
                thumb: require("@/assets/img/founder3_thumb.png"),
                name: "charles_lo",
                role: "Classmade Founder",
                education_en: `Industrial Design and Web Design New Media in Academy of Art University, San Francisco`,
                education_cn: `Industrial Design and Web Design New Media in Academy of Art University, San Francisco`,
                current_position_en: `Charles is the founder of Classmade - a platform dedicated to help students find their passion and motivation for their future. In collaboration with Findlinc, we strive to create an accessible and engaging tool for our future generations.`,
                current_position_cn: `Charles is the founder of Classmade - a platform dedicated to help students find their passion and motivation for their future. In collaboration with Findlinc, we strive to create an accessible and engaging tool for our future generations.`,
                }
            ]
        }
    },

    components: {
        Founder: () => import('@/components/landing-page/FounderModal')
    },

    methods: {
        openModal (val) {
            this.founder = this.founders[val]
            this.showModal = true
        },

        close () {
            this.founder = null
            this.showModal = false
        }
    },
}
</script>

<style lang="scss" scope>
.about {
    color: #221E1F;

    .about-wrapper {
        // width: 1200px;
        text-align: center;

        @include desktop {
            width: 1200px;
            text-align: unset;
        }

        h2 {
            text-align: center;
            font-family: 'Crimson Pro', sans-serif;
            font-weight: 700;
            font-size: 34px;
            padding-bottom: 30px;
            padding-top: 30px;
            border-bottom: 1px solid #B8B8B8;
        }

        .title {
            text-align: center;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 700;
            font-size: 20px;
            margin: 30px auto 20px;
        }

        .ender {
            text-align: center;
            font-weight: 700;
            font-size: 24px;
            margin: 30px auto 20px;
        }

        .desc {
            font-size: 16px;
            letter-spacing: 0.22px;
            line-height: 28px;
            margin-bottom: 30px;
        }

        .founders-container {
            margin: 0px auto 30px;

            .image-container {
                display: flex;
                justify-content: center;
                margin: 35px auto 0px;
                flex-direction: column;

                @include desktop {
                    flex-direction: row;
                }

                .profile-container {
                    text-align: center;
                    margin-bottom: 40px;

                    @include desktop {
                        margin-right: 40px;
                    }

                    img {
                        width: 200px;
                        height: 200px;
                        margin-bottom: 16px;
                        pointer-events: none;

                        @include desktop {
                            pointer-events: auto;
                            cursor: pointer;
                        }
                    }

                    .name {
                        font-family: 'Crimson Pro', sans-serif;
                        font-weight: 700;
                        font-size: 18px;
                        margin-bottom: 8px;
                    }

                    .position {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .modal-container {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000000a1;
        z-index: 999999;
        overflow-y: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .founder-container {
            // height: 600px;
            width: 1200px;
        }
    }
}
</style>